var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "leaflet-view" },
    [
      _c("SelectedStore"),
      _c("h4", { staticClass: "text mt-4" }, [
        _vm._v(_vm._s(_vm.$t("leaflet.chooseStore")))
      ]),
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c(
            "v-col",
            { staticClass: "d-flex", attrs: { cols: "6" } },
            [
              _c("v-select", {
                staticClass: "custom-select",
                attrs: {
                  items: _vm.provinceList,
                  "item-text": "name",
                  "item-value": "id",
                  placeholder: "Provincia",
                  outlined: "",
                  rounded: "",
                  "hide-details": "",
                  "no-data-text": _vm.$t("$vuetify.noDataText")
                },
                on: {
                  change: function($event) {
                    return _vm.filterByProvince()
                  }
                },
                model: {
                  value: _vm.provinceId,
                  callback: function($$v) {
                    _vm.provinceId = $$v
                  },
                  expression: "provinceId"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex", attrs: { cols: "6" } },
            [
              _c("v-select", {
                staticClass: "custom-select",
                attrs: {
                  items: _vm.availableCity,
                  "item-text": "name",
                  "item-value": "id",
                  placeholder: "Città",
                  outlined: "",
                  rounded: "",
                  "hide-details": "",
                  "no-data-text": _vm.$t("$vuetify.noDataText")
                },
                on: {
                  change: function($event) {
                    return _vm.findWarehouse()
                  }
                },
                model: {
                  value: _vm.city_id,
                  callback: function($$v) {
                    _vm.city_id = $$v
                  },
                  expression: "city_id"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-tabs",
        {
          staticClass: "segment-style-tabs mt-5",
          attrs: {
            grow: "",
            color: "white",
            "background-color": "grey lighten-1"
          },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c("v-tab", { attrs: { ripple: false } }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("leaflet.selectionType.list")))])
          ]),
          _c("v-tab", { attrs: { ripple: false } }, [
            _c("span", [_vm._v(_vm._s(_vm.$t("leaflet.selectionType.map")))])
          ])
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          attrs: { touchless: "" },
          model: {
            value: _vm.tab,
            callback: function($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c(
            "v-tab-item",
            [_c("AddressList", { attrs: { addressList: _vm.warehouseList } })],
            1
          ),
          _c(
            "v-tab-item",
            { attrs: { touch: false } },
            [
              _c(
                "GmapMap",
                {
                  ref: "mapRef",
                  staticStyle: { width: "100%", height: "500px" },
                  attrs: {
                    center: {
                      lat: _vm.userLocation.lat,
                      lng: _vm.userLocation.lng
                    },
                    zoom: _vm.userLocation.zoom,
                    options: {
                      zoomControl: true,
                      mapTypeControl: false,
                      scaleControl: false,
                      streetViewControl: false,
                      rotateControl: false,
                      fullscreenControl: false,
                      disableDefaultUI: false
                    }
                  }
                },
                [
                  _c(
                    "GmapCluster",
                    {
                      attrs: {
                        imagePath: "/img_layout/map_pointer_placeholder",
                        zoomOnClick: true,
                        gridSize: 40
                      }
                    },
                    [
                      _vm._l(_vm.warehouseList, function(m, index) {
                        return _c("GmapMarker", {
                          key: index,
                          attrs: {
                            position:
                              _vm.google &&
                              new _vm.google.maps.LatLng(
                                m.address.latitude,
                                m.address.longitude
                              ),
                            clickable: true,
                            icon: "/img_layout/map_pointer.png"
                          },
                          on: {
                            click: function($event) {
                              return _vm.toggleInfoWindow(m, index)
                            }
                          }
                        })
                      }),
                      _c(
                        "gmap-info-window",
                        {
                          attrs: {
                            position: _vm.infoWindowPos,
                            opened: _vm.infoWinOpen
                          },
                          on: {
                            closeclick: function($event) {
                              _vm.infoWinOpen = false
                            }
                          }
                        },
                        [
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(_vm.infoOptions.content)
                            }
                          }),
                          _c(
                            "v-btn",
                            {
                              staticClass: "my-1 info-window-btn",
                              attrs: {
                                small: "",
                                rounded: "",
                                block: "",
                                color: "primary"
                              },
                              on: { click: _vm.openDetailsPage }
                            },
                            [_vm._v("SCHEDA PUNTO VENDITA")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "my-1 info-window-btn",
                              attrs: {
                                small: "",
                                rounded: "",
                                block: "",
                                color: "primary",
                                outlined: ""
                              },
                              on: { click: _vm.openGoogleMaps }
                            },
                            [_vm._v("INDICAZIONI")]
                          )
                        ],
                        1
                      )
                    ],
                    2
                  ),
                  _vm.showUserLocation
                    ? _c("gmap-marker", {
                        attrs: { position: _vm.userLocation }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }