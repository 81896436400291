<template>
  <div class="address-list">
    <v-list dense flat class="custom-list px-0">
      <v-list-item-group color="primary">
        <v-list-item
          v-for="(item, i) in addressList"
          :key="'address-' + i"
          class="px-2"
          :to="'/punti-vendita/' + item.warehouseId"
        >
          <v-list-item-content>
            <v-list-item-title class="primary--text">{{
              item.name
            }}</v-list-item-title>
            <v-list-item-title class="default--text"
              >{{ item.address.city }} -
              {{ item.address.province }}</v-list-item-title
            >
            <v-list-item-subtitle
              v-html="item.address.addressName"
            ></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-icon>$chevronRight</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>
<style lang="scss">
.address-list {
  .custom-list {
    .v-list-item {
      .v-list-item__content {
        .v-list-item__title {
          font-size: 16px !important;
          line-height: 20px;
          font-weight: 600;
        }
        .v-list-item__subtitle {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
<script>
export default {
  name: "AddressList",
  props: {
    addressList: { type: Array, required: true }
  }
};
</script>
