var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "address-list" },
    [
      _c(
        "v-list",
        { staticClass: "custom-list px-0", attrs: { dense: "", flat: "" } },
        [
          _c(
            "v-list-item-group",
            { attrs: { color: "primary" } },
            _vm._l(_vm.addressList, function(item, i) {
              return _c(
                "v-list-item",
                {
                  key: "address-" + i,
                  staticClass: "px-2",
                  attrs: { to: "/punti-vendita/" + item.warehouseId }
                },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        { staticClass: "primary--text" },
                        [_vm._v(_vm._s(item.name))]
                      ),
                      _c(
                        "v-list-item-title",
                        { staticClass: "default--text" },
                        [
                          _vm._v(
                            _vm._s(item.address.city) +
                              " - " +
                              _vm._s(item.address.province)
                          )
                        ]
                      ),
                      _c("v-list-item-subtitle", {
                        domProps: {
                          innerHTML: _vm._s(item.address.addressName)
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-action",
                    [_c("v-icon", [_vm._v("$chevronRight")])],
                    1
                  )
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }